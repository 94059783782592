
<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">{{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[18]}}
            </router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.museumId.address}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="m_sidebar">
              <div class="m_sidebar_title">
                {{$store.state.language.lang[1]}}
              </div>
              <div class="m_sidebar_link">
                <router-link to="/agency/management" class="menu_drops">
                  {{$store.state.language.lang[10]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/central/office" class="menu_drops">
                  {{$store.state.language.lang[9]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/structure" class="menu_drops">
                  {{$store.state.language.lang[11]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/council" class="menu_drops">
                  {{$store.state.language.lang[12]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/territorial" class="menu_drops">
                  {{$store.state.language.lang[13]}}
                </router-link>
              </div>

              <div class="m_sidebar_link">
                <router-link to="/regulations" class="menu_drops">
                  {{$store.state.language.lang[15]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/organization" class="menu_drops">
                  {{$store.state.language.lang[16]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/activity" class="menu_drops">
                  {{$store.state.language.lang[17]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/museum" class="menu_drops">
                  {{$store.state.language.lang[18]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/vacancy" class="menu_drops">
                  {{$store.state.language.lang[19]}}
                </router-link>
              </div>

            </div>
          </div>
          <div class="col-md-9">
            <div class="m_body_title">
              <h2>{{$store.state.museumId.title}}</h2>
            </div>
            <div class="museum_card_id" v-if="$store.state.museumId">
              <div v-for="(item,index) in $store.state.museumId.images" :key="index">
                <img :src="item.image" alt="" width="100%">
              </div>
              <div  class="muse_description" v-html="$store.state.museumId.description">
              </div>
            </div>
            <div class="museum_card_id m_data_card">
              <div class="m_card_title_id">

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9984 21.332C11.9984 21.332 4.23438 16.667 4.23438 10.444C4.23438 6.14799 7.71637 2.66599 12.0124 2.66599C16.3084 2.66599 19.7904 6.14799 19.7904 10.444C19.7904 16.667 11.9984 21.332 11.9984 21.332Z" stroke="#05057D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.0124 13.556C13.7305 13.556 15.1234 12.1631 15.1234 10.445C15.1234 8.72683 13.7305 7.33399 12.0124 7.33399C10.2942 7.33399 8.90137 8.72683 8.90137 10.445C8.90137 12.1631 10.2942 13.556 12.0124 13.556Z" stroke="#05057D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ $store.state.museumId.address }}
              </div>
              <div class="m_card_title_id">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.998 12C19.998 16.4172 16.4171 19.998 12 19.998M19.998 12C19.998 7.58284 16.4171 4.00201 12 4.00201M19.998 12H4.00195M12 19.998C7.58278 19.998 4.00195 16.4172 4.00195 12M12 19.998C13.7943 19.998 15.249 16.4172 15.249 12C15.249 7.58284 13.7943 4.00201 12 4.00201M12 19.998C10.2056 19.998 8.75095 16.4172 8.75095 12C8.75095 7.58284 10.2056 4.00201 12 4.00201M4.00195 12C4.00195 7.58284 7.58278 4.00201 12 4.00201" stroke="#05057D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ $store.state.museumId.website }}
              </div>
              <div class="m_card_title_id">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.60562 14.365C10.5056 15.228 11.5606 15.93 12.7266 16.428L13.2456 16.649L14.8406 15.029C15.2366 14.627 15.8096 14.453 16.3626 14.569L20.2266 15.378C20.8806 15.517 21.3476 16.094 21.3476 16.762V19.544C21.3476 20.539 20.5416 21.345 19.5466 21.345H18.6916C14.7576 21.345 10.8756 20.042 7.93962 17.424C7.69762 17.209 7.46262 16.986 7.23362 16.758L7.27062 16.795C7.04162 16.566 6.81962 16.331 6.60462 16.089C3.98562 13.154 2.68262 9.27201 2.68262 5.33801V4.48301C2.68262 3.48801 3.48862 2.68201 4.48362 2.68201H7.26562C7.93362 2.68201 8.51062 3.14901 8.64962 3.80301L9.45962 7.66701C9.57462 8.21901 9.40162 8.79301 8.99962 9.18901L7.37962 10.784L7.60062 11.303C8.09862 12.468 8.74162 13.465 9.60562 14.365Z" stroke="#05057D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                {{ $store.state.museumId.phone_number }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../Header/Navbar";
import Footer from "../../Header/Footer";

export default {
  components: {
    Footer,
    Navbar
  },

  data() {
    return {
      isActive_m: false
    }
  },
  mounted() {
    this.$store.dispatch('museumId')
  },
  methods: {
    open_modal() {

    }
  }
}
</script>
